.App {
  text-align: center;
  color: #5f6b59;
  background-color: #232d19;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
}
