.wrapper_projects {
  position: relative;
  max-width: 90vw;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  animation: slideInProjects 0.8s ease-in-out;
  margin-bottom: 5vh;
}

.projects_left_container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding-bottom: 35vh;
  padding-left: 8%;
  width: 45%;
  height: 100vh;
}
.header_container h1 {
  font-family: 'Montserrat', sans-serif;
  color: #98a396;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.projects_section-paragraph p {
  text-align: left;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 19px;
}
.projects_right_container {
  display: flex;
  width: 70%;
  height: 90vh;
  padding-top: 5vh;
  padding-bottom: 3vh;
  padding-left: 20%;
  float: right;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
}

.project_link_container {
  transition: transform 200ms;
  width: 70vw;
  cursor: context-menu;
}

.project_link {
  z-index: 50;
  display: block;
  border: 0px solid #000;
  font-family: 'Playfair display', serif;
  font-style: italic;
  color: #5f6b59;
  font-size: 4.6vw;
  letter-spacing: 5px;
  font-weight: 300;
  text-decoration: none;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
}
.project_desc {
  margin: 0 0 0 20px;
}

.btn_small {
  display: inline-block;
  margin: 10px 20px;
  transition: 250ms ease-in-out;
  font-family: 'Playfair display', sans-serif;
  color: #5f6b59;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 1px;
  cursor: pointer;
  text-decoration: none;
}

@keyframes slideInProjects {
  0% {
    transform: translate(-500px, 500px) scale(0);
  }
  100% {
    transform: translate(0px) scale(1);
  }
}
@media screen and (max-width: 1100px) {
  .big_wrapper {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
  }
  .wrapper_projects {
    max-width: 100vw;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 2.7vw;
    padding-bottom: 2vh;
  }
  .projects_left_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0;
    margin: 0 auto;
    width: 80vw;
    height: auto;
  }
  .header_container h1 {
    width: 80vw;
    font-size: 5vw;
    letter-spacing: 3px;
    text-align: center;
    padding: 0;
  }
  .projects_section-paragraph p {
    width: 80vw;
    font-size: 2.1vw;
    text-align: center;
    padding: 0;
  }
  .projects_right_container {
    display: flex;
    width: 80vw;
    height: auto;
    padding-top: 5vh;
    padding-bottom: 0;
    padding-left: 0;
    text-align: center;
  }
  .project_link {
    font-size: 5.2vw;
  }
  .project_link_container:hover {
    transform: translate(0);
  }
}

@media screen and (max-width: 900px) {
  .wrapper_projects {
    padding-top: 10vh;
  }

  .projects_right_container {
    width: 90vw;
    padding-top: 6vh;
  }
  .project_link {
    font-size: 4.8vw;
    margin-top: 5px;
  }
  .project_desc {
    font-size: 2vw;
  }
}
@media screen and (max-width: 630px) {
  .wrapper_projects {
    padding-top: 12vh;
  }
  .projects_section-paragraph p {
    font-size: 3vw;
  }
  .header_container h1 {
    font-size: 6vw;
  }
  .project_link {
    font-size: 5.2vw;
    margin-top: 10px;
  }
  .project_desc {
    font-size: 2.8vw;
  }
}

@media screen and (max-width: 400px) {
  .wrapper_projects {
    padding-top: 10vh;
  }
  .projects_section-paragraph p {
    font-size: 4vw;
  }
  .header_container h1 {
    font-size: 7vw;
  }
  .project_link {
    font-size: 6.2vw;
    margin-top: 15px;
  }
  .project_desc {
    font-size: 3.2vw;
  }
}
