.navbar {
  position: fixed;
  z-index: 99999;
  display: flex;
  height: 100vh;
  bottom: 0;
  padding-top: 5%;
  padding-bottom: 30px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.nav-link-item {
  margin-bottom: 40px;
  padding-right: 15px;
  padding-left: 20px;
}

.nav-link {
  display: block;
  border: 0px solid #000;
  font-family: Montserrat, sans-serif;
  color: #5f6b59;
  font-size: 25px;
  font-weight: 500;
  text-align: center;
  letter-spacing: 5px;
  text-decoration: none;
  text-transform: uppercase;
  transition: 250ms ease-in-out;
}
.line {
  width: 1px;
  height: 150px;
  background-color: #5f6b59;
}

.nav-link:hover {
  transform: scale(1.5);
}
@media screen and (max-width: 600px) {
  .navbar {
    flex-direction: row;
    top: 0;
    bottom: auto;
    height: auto;
    width: 100vw;
    justify-content: center;
  }
  .line {
    display: none;
  }
}
