.about_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 80%;
  padding-left: 15%;
  margin-top: 0;
  animation: slideInAbout 0.8s ease-in-out;
}

.about_wrapper h1 {
  font-family: 'Playfair display', sans-serif;
  font-size: 13vw;
  font-weight: 300;
  letter-spacing: 0px;
  margin-bottom: 0vh;
}
.about_wrapper h2 {
  font-family: 'Playfair display', sans-serif;
  font-weight: 300;
  font-size: 3vw;
  letter-spacing: 0px;
  margin-bottom: 3vh;
}
.about_text_container {
  margin-bottom: 30vh;
}
.about_text_container p {
  display: block;
  max-width: 70%;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  line-height: 2.1em;
  font-weight: 400;
  text-align: start;
  z-index: 30;
  margin-top: 4vh;
}
.icon {
  top: -60px;
  left: 40vw;
  font-size: 250px;
  position: fixed;
  height: 20px;
  color: #5f6b59;
  opacity: 20%;
  z-index: 2;
  display: block;
}
.btn {
  display: block;
  margin: 30px 0 40px 20%;
  padding: 15px 50px;
  border-radius: 50px;
  background-color: #565f51;
  border: 0px;
  transition: 250ms ease-in-out;
  font-family: 'Playfair display', sans-serif;
  font-weight: 500;
  letter-spacing: 1px;
  cursor: pointer;
}

.btn a {
  text-decoration: none;
  color: #443d26;
  font-size: 47px;
}
.btn:hover {
  transform: scale(1.4);
}

@keyframes slideInAbout {
  0% {
    transform: translateY(500px) scale(0);
  }
  100% {
    transform: translateX(0px) scale(1);
  }
}

@media screen and (max-width: 1250px) {
  .about_wrapper h2 {
    font-size: 3.3vw;
  }
}
@media screen and (max-width: 1050px) {
  .about_wrapper {
    width: 85vw;
  }
  .about_wrapper h2 {
    font-size: 3.9vw;
  }
  .about_text_container p {
    max-width: 85vw;
  }
}
@media screen and (max-width: 880px) {
  .about_wrapper h1 {
    font-size: 16vw;
  }
  .about_wrapper h2 {
    font-size: 4.3vw;
  }
}
@media screen and (max-width: 600px) {
  .about_wrapper {
    width: 95vw;
    padding-left: 6vw;
    padding-right: 6vw;
  }
  .about_wrapper h1 {
    font-size: 16vw;
  }
  .about_wrapper h2 {
    font-size: 6vw;
  }
  .about_text_container p {
    font-size: 17px;
  }
  .btn {
    margin: 30px auto 40px;
  }
}

@media screen and (max-width: 300px) {
  .about_text_container p {
    font-size: 15px;
  }
  .btn {
    font-size: 30px;
  }
}
