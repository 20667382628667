.big_wrapper {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}
.wrapper {
  position: relative;
  max-width: 80%;
  padding-left: 6%;
  padding-right: 5%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  animation: slideIn 0.8s ease-in-out;
  margin-bottom: 5vh;
}

.left_container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 20vh;
  width: 25%;
  height: 100vh;
}
.header_home_container h1 {
  font-family: 'Montserrat', sans-serif;
  color: #98a396;
  font-size: 19px;
  font-weight: 500;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.left_container p {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
}
.right_container {
  display: flex;
  width: 70%;
  height: 90vh;
  padding-top: 5vh;
  padding-bottom: 3vh;
  padding-left: 25%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.links_nav {
  padding-top: 10%;
  padding-left: 10%;
  text-align: left;
}
.route_link {
  z-index: 50;
  display: block;
  font-family: 'Playfair display', serif;
  font-style: italic;
  color: #5f6b59;
  font-size: 12vw;
  letter-spacing: 5px;
  line-height: 0.95em;
  font-weight: 300;
  text-decoration: none;
  text-transform: uppercase;
  transition: transform 150ms;
}

.route_link:hover {
  transform: translate(35px, -3px);
}

@media screen and (max-width: 1450px) {
  .wrapper {
    padding-left: 4%;
  }
  .left_container {
    width: 32%;
  }
  .right_container {
    width: 60%;
  }
}
@media screen and (max-width: 1100px) {
  .big_wrapper {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
  }
  .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-left: 20vw;
  }
  .left_container {
    height: auto;
    width: 40vw;
    padding-bottom: 5vw;
    padding-top: 10vw;
  }
  .right_container {
    width: 80vw;
    height: auto;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
  }
  .links_nav {
    padding-top: 3vh;
    text-align: center;
  }

  .header_home_container h1 {
    font-size: 25px;
  }
}
@media screen and (max-width: 780px) {
  .left_container {
    padding-top: 13vw;
  }
}
@media screen and (max-width: 600px) {
  .left_container {
    width: 60vw;
    padding-bottom: 10vw;
    padding-top: 20vw;
  }
  .right_container {
    width: 100vw;
  }

  .header_home_container h1 {
    font-size: 25px;
  }
}

@media screen and (max-width: 430px) {
  .left_container {
    padding-top: 30vw;
  }
  .header_home_container h1 {
    font-size: 21px;
  }
}
@media screen and (max-width: 300px) {
}

@keyframes slideIn {
  0% {
    transform: translateX(500px) scale(0);
  }
  100% {
    transform: translateX(0px) scale(1);
  }
}
