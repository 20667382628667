.contact_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  width: 70%;
  height: 100vh;
  padding-bottom: 20vh;
  padding-left: 10%;
  animation: slideInContact 0.8s ease-in-out;
}

.contact_wrapper h1 {
  font-family: 'Playfair display', sans-serif;
  font-size: 11vw;
  /* line-height: 1em; */
  font-weight: 300;
  text-align: center;
  letter-spacing: 0px;
  margin-bottom: 0vh;
}
.desc_container p {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.2vw;
  font-weight: 500;
  text-align: left;
  margin: 0.3vw;
}

.desc_container p a {
  font-style: italic;
  font-weight: 500;
  text-align: left;
  text-decoration: none;
  color: #5f6b59;
  text-decoration: underline;
  transition: transform 150ms;
}

@media screen and (max-width: 1450px) {
  .contact_wrapper {
    width: 90%;
  }
  .contact_wrapper h1 {
    font-size: 13vw;
  }
  .desc_container p {
    font-size: 1.7vw;
  }
}
@media screen and (max-width: 920px) {
  .contact_wrapper h1 {
    font-size: 15vw;
  }
  .desc_container p {
    font-size: 2.3vw;
  }
}
@media screen and (max-width: 600px) {
  .contact_wrapper {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .desc_container p {
    text-align: center;
    font-size: 2.65vw;
    line-height: 3.6vw;
  }
}

@media screen and (max-width: 300px) {
  .contact_wrapper h1 {
    font-size: 17vw;
  }
  .desc_container p {
    text-align: center;
    font-size: 3.65vw;
    line-height: 4.5vw;
  }
}

@keyframes slideInContact {
  0% {
    transform: translateY(-500px) scale(0);
  }
  100% {
    transform: translateX(0px) scale(1);
  }
}
@media screen and (max-width: 1450px) {
}
@media screen and (max-width: 920px) {
}
@media screen and (max-width: 600px) {
}

@media screen and (max-width: 300px) {
}
